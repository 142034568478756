import {Timeline} from "antd";

export const Diary = () => {
	return (
		<section id="diary" className={`d-flex-col mb-6`}>
			<header className="text-center">
				<h2>Diary</h2>
			</header>
			<div className="mt-5">
				<Timeline mode="left">
					<Timeline.Item color="green" label="(1. week) 14.2.2022 - 20.2.2022">
						<ul style={{padding: 0, margin: "0.5rem"}}>
							<li>Web App - versioning system for a current uploaded 3D model.</li>
							<li>Web App - separating backend endpoints to custom modules.</li>
							<li>Web App - refactoring to the new versioning system & algorithms.</li>
							<li>Gathered SHARP datasets.</li>
						</ul>
					</Timeline.Item>
					<Timeline.Item color="green" label="(2. week) 21.2.2022 - 27.2.2022">
						<ul style={{padding: 0, margin: "0.5rem", marginTop: "0"}}>
							<li>Web App - Implemented & adjusted analytical outlier removal algorithms to a new logic.</li>
							<li>
								Studied SHARP challenges (<a href="https://cvi2.uni.lu/sharp2022/">link</a>) & researched methods)
							</li>
						</ul>
					</Timeline.Item>
					<Timeline.Item color="green" label="(3. week) 28.2.2022 - 6.3.2022">
						<ul style={{padding: 0, margin: "0.5rem", marginTop: "0"}}>
							<li>Studied logic behind auto-encoders.</li>
							<li>Proposed first architecture design.</li>
							<li>Started with implementation.</li>
							<li>Preparing dataset for training&testing.</li>
							<li>Implementation of the PointNet in keras.</li>
						</ul>
					</Timeline.Item>
					<Timeline.Item color="green" label="(4. week) 7.3.2022 - 13.3.2022">
						<ul style={{padding: 0, margin: "0.5rem", marginTop: "0"}}>
							<li>Web App: Visualizing meshes with proper textures/materials.</li>
							<li>Web App: Centering camera to an uploaded model.</li>
							<li>SHARP: Parsing dataset using h5py (to load dataset as numpy arrays faster).</li>
							<li>SHARP: Moving away from point-set generation & auto-encoder ideas as they would require much larger
								and sufficient networks with time-consuming parameter optimization for better results.
							</li>
							<li>SHARP: Implementing and testing custom architectures.
								Logic behind these architectures is to predict offsets that are applied on the training/testing sampled
								point clouds from an original meshes.
								Even with simple MLP networks, the results are much better compared to the original PointNet architecture
								that was used to re-generate new point cloud (which is much harder task in general).
							</li>
							<li>SHARP: Model/weights saving, different weight initializers, CUDA optimization, ...</li>
						</ul>
					</Timeline.Item>
					<Timeline.Item color="green" label="(5. week) 14.3.2022 - 20.3.2022">
						<ul style={{padding: 0, margin: "0.5rem", marginTop: "0"}}>
							<li>(bug fix) Web App: loading another 3D model in one working session.</li>
							<li>Web App: Switching between point cloud & mesh visualization modes.</li>
							<li>SHARP: Over night training.</li>
							<li>SHARP: Setup of an evaluation in docker following instruction in the competition.</li>
							<li>Testing different optimizers and doing hyper-parameters tuning.</li>
						</ul>
					</Timeline.Item>
					<Timeline.Item color="green" label="(6. week) 21.3.2022 - 27.3.2022">
						<ul style={{padding: 0, margin: "0.5rem", marginTop: "0"}}>
							<li>More hyper-parameters tuning.</li>
							<li>Training on higher resolution point clouds (16k).</li>
							<li>
								Adjusted chamfer loss to use random 4k points for comparison with ground truth so we can train & evaluate
								on GPUs.
							</li>
							<li>
								Final results of the MLP architecture that predicts offsets to sampled ground truth CAD models
								with chamfer loss function.
							</li>
							<li>Research on edge extraction tools & testing them.</li>
						</ul>
					</Timeline.Item>
					<Timeline.Item color="green" label="(7. week) 28.3.2022 - 2.4.2022">
						<ul style={{padding: 0, margin: "0.5rem", marginTop: "0"}}>
							<li>Docker evaluation with final scoring.</li>
							<li>More edge extraction testing.</li>
							<li>Proposed next methods.</li>
							<li>Extraction and conversion of 3D models in the web app with fixed exporting as mesh or point cloud.</li>
							<li>Thesis writing.</li>
						</ul>
					</Timeline.Item>
					<Timeline.Item color="green" label="(8. week) 3.4.2022 - 9.4.2022">
						<ul style={{padding: 0, margin: "0.5rem", marginTop: "0"}}>
							<li>
								Writing thesis: more on theory, web app high-level system architecture, REST API design, front-end
								component hierarchy, versioning, export and conversion, started with algorithms section, all SHARP methods.
							</li>
							<li>ŠVK writing</li>
							<li>
								Web app: implemented versioning system, front-end refactor of algorithms with new data structure making
								it simple to add new algorithms.
							</li>
							<li>Sampling vertices in the scan meshes by sharp edges (ply).</li>
							<li>On-paper constructed new approaches.</li>
							<li>Sampling points in mesh scans by PLY sharp edge points extracted from CAD models.</li>
						</ul>
					</Timeline.Item>
					<Timeline.Item color="green" label="(9. week) 10.4.2022 - 16.4.2022">
						<ul style={{padding: 0, margin: "0.5rem", marginTop: "0"}}>
							<li>Writing thesis: more on theory, frontend: 3D scene & model, component hierarchy.</li>
							<li>Training & testing new methods: assigning NN from GT to each scan in augmentation process.</li>
							<li>Training & testing new methods: Same but only for extracted edges.</li>
							<li>Improved edge extraction method.</li>
							<li>Finished writing of ŠVK submission paper.</li>
						</ul>
					</Timeline.Item>
					<Timeline.Item color="green" label="(10. week) 17.4.2022 - 23.4.2022">
						<ul style={{padding: 0, margin: "0.5rem", marginTop: "0"}}>
							<li>Web app development: finished UI styling.</li>
							<li>Web app development: loading and saving state from JSON.</li>
							<li>
									Writing thesis: kd-tree, mesh (normals), dihedral angle,
									finished chapter about the web app with diagrams and code samples.
							</li>
						</ul>
					</Timeline.Item>
					<Timeline.Item color="green" label="(11. week) 24.4.2022 - 30.4.2022">
						<ul style={{padding: 0, margin: "0.5rem", marginTop: "0"}}>
							<li>Web app development: Implemented algorithms: Poisson surface reconstruction, edge extraction.</li>
							<li>Logo & title</li>
							<li>Web app development: Solved caching issue.</li>
							<li>Preparing presentation for the ŠVK.</li>
							<li>Writing and submitting paper for the ŠVOČ.</li>
							<li>Almost finished writing about each method of SHARP in the bachelor's thesis.</li>
						</ul>
					</Timeline.Item>
					<Timeline.Item color="green" label="(12. week) 1.5.2022 - 7.5.2022">
						<ul style={{padding: 0, margin: "0.5rem", marginTop: "0"}}>
							<li>Finished chapter about 3D processing.</li>
						</ul>
					</Timeline.Item>
					<Timeline.Item color="green" label="(13. week) 8.5.2022 - 14.5.2022">
						<ul style={{padding: 0, margin: "0.5rem", marginTop: "0"}}>
							<li>Processed notes from the supervisor to the thesis.</li>
							<li>Finished (re-written) terminology and definitions chapter.</li>
							<li>Finished introduction chapter.</li>
							<li>Fixed bug inside the web app, where the browser would freeze on specific uploaded data formats.</li>
						</ul>
					</Timeline.Item>
					<Timeline.Item color="green" label="(14. week) 15.5.2022 - 21.5.2022">
						<ul style={{padding: 0, margin: "0.5rem", marginTop: "0"}}>
							<li>More generalized code of Algorithm component in Section 5.6.</li>
							<li>Finished sharp figures, digitalized figures of diagrams in the web app chapter, and sharp results.</li>
							<li>Finished chapters and sections: Goals, Conclusion, Deployment, Scalability,
									Methods (more details about react, redux, deep learning on point clouds.
							</li>
							<li>Shortly described CNN.</li>
							<li>Start citations from [1].</li>
						</ul>
					</Timeline.Item>
					<Timeline.Item color="green" label="(15. week) 22.5.2022 - 28.5.2022">
						<ul style={{padding: 0, margin: "0.5rem", marginTop: "0"}}>
							<li>Written abstract, acknowledgements, fixed citations and some grammar issues.</li>
							<li>Merged repositories together.</li>
							<li>Finished refactoring and technical specifications.</li>
						</ul>
					</Timeline.Item>
					<Timeline.Item color="green" label="(16. week) 29.5.2022 - 3.6.2022">
						<ul style={{padding: 0, margin: "0.5rem", marginTop: "0"}}>
							<li>Submitted finished thesis and refactored code.</li>
						</ul>
					</Timeline.Item>
				</Timeline>
			</div>
		</section>
	)
}