import Particles from "react-tsparticles";

// https://github.com/matteobruni/tsparticles/tree/main/components/react

export const BackgroundParticles = () => {

	const particlesInit = (engine: any): any => {
		console.log(engine);
	};

	return (
		<div
			style={{
				position: "absolute",
				zIndex: -1,
				width: "100%",
				height: "100%",
				top: 0,
				left: 0
			}}
		>
			<Particles
				id="tsparticles"
				init={particlesInit}
				options={{
					fullScreen: {enable: false},
					fpsLimit: 120,
					particles: {
						color: {
							value: "#ffffff",
						},
						links: {
							color: "#ffffff",
							distance: 200,
							enable: true,
							opacity: 0.5,
							width: 1,
						},
						collisions: {
							enable: true,
						},
						move: {
							direction: "none",
							enable: true,
							outMode: "bounce",
							random: false,
							speed: 1,
							straight: false,
						},
						number: {
							density: {
								enable: true,
								area: 700,
							},
							value: 25,
						},
						opacity: {
							value: 0.4,
						},
						shape: {
							type: "circle",
						},
						size: {
							random: true,
							value: 4,
						},
					},

					detectRetina: true,
				}}
			/>
		</div>
	)
}