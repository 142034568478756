
export const Aim = () => {
  return (
	  <section id="aim" className={`d-flex-col align-items-center mb-6`}>
		  <header className="text-center">
			  <h2>Aim</h2>
		  </header>
		  <div className={`text-justify`} style={{width: "600px"}}>
			  <ul style={{padding: 0}}>
				  <li>propose and develop a web application for data format conversion of point
					  clouds, 3D scans, 3D meshes and other 3D data using existing tools.</li>
				  <li>study existing analytical and data-driven processing algorithms</li>
				  <li>design and develop a processing algorithm as a sequence of various parts,
					  while allowing the user to choose between them and modify parameters</li>
				  <li>optimize algorithms for client-side or server-side usage</li>
			  </ul>
		  </div>
	  </section>
  )
}