import React from 'react';
import {BrowserRouter as Router, Route, Switch} from "react-router-dom";
import "static/styles/base.scss";
import {BachelorThesis} from "pages/bachelor-thesis/BachelorThesis";
import {NotFound} from "pages/not-found/NotFound";
import {Home} from "./pages/home/Home";

// https://ant.design/docs/react/use-with-create-react-app#Install-and-Initialization


const App = () => {
  return (
    <Router>
      <Switch>
        <Route exact path="/" component={Home}/>
        <Route exact path="/bachelor-thesis" component={BachelorThesis}/>
        <Route exact path="*" component={NotFound} />
      </Switch>
    </Router>
  );
};

export default App;
