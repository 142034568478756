import {Timeline} from "antd";

export const Milestones = () => {
  return (
	  <section id="milestones" className={`d-flex-col mb-6`}>
		  <header className="text-center">
			  <h2>Timeline & Milestones</h2>
		  </header>
		  <div className="mt-5">
			  <Timeline mode="left">
				  <Timeline.Item color="green" label="30.11.2021">
					  Studied main literature, existing analytical and data-driven processing algorithms,
					  other relevant sources.
				  </Timeline.Item>
				  <Timeline.Item color="green" label="6.12.2021">
					  First version of the web application (software design, basic layout, backend API with main routes).
				  </Timeline.Item>
				  <Timeline.Item color="green" label="20.12.2021">
					  Backend communicating with data conversion software. Format conversion of 3D data as are e.g.
					  point clouds, meshes, etc..
				  </Timeline.Item>
				  <Timeline.Item color="green" label={<div>15.1.2022<br/>MVP (alpha)</div>}>
					  Studied basics of neural networks, in-depth gradient descent, MLPs, backpropagation, etc.<br/>
					  10 pages of thesis (Terminology, definitions; Methods).<br/>
					  Web application with ability to visualize and provide simple manipulation over point cloud data files.
					  Data format conversion of 3D data.
					  Implementation, visualization and parametrization of some existing processing algorithms over 3D data,
					  where we'll see which ones are more suitable to run them on the backend and which ones directly
					  on the frontend.
				  </Timeline.Item>
				  <Timeline.Item color="green" label={<div>6.2.2022<br/>MVP (beta)</div>}>
					  Studied convolution neural networks and dynamic graph cnn paper. <br/>
					  Implementation and evaluation using existing code of PointNet and DGCNN. <br/>
					  Basic styling of web application and improved user experience for the uploading, converting, visualizing,
					  switching and running algorithms.
				  </Timeline.Item>
				  <Timeline.Item label="20.2.2022">
					  <ul style={{padding: 0, margin: "0.75rem"}}>
						  <li>UML diagrams (data flow, frontend to backend communication, etc.).</li>
						  <li>Deployed web app & optimized one-user session.</li>
					  </ul>
				  </Timeline.Item>
				  <Timeline.Item label="27.2.2022">
					  Researched, studied and proposed architecture & method for the CVPR challenge 1 track 2 / challenge 2 track 1
					  (<a href="https://cvi2.uni.lu/sharp2022/challenge1/">Recovery of Partial Textured Scans</a>,
					   <a href="https://cvi2.uni.lu/sharp2022/challenge2/">
						   Recovery of Parametric Sharp Edges in 3D Object Scans
					   </a>). <br/>
					  Deciding whether continuing on this challenge. <br/>
					  Decision: continuing on the challenge 2 track 1.
				  </Timeline.Item>
				  <Timeline.Item label="13.3.2022">
					  <a href="https://cvi2.uni.lu/sharp2022/challenge2/">SHARP</a>: First results from training on researched architecture/s.
				  </Timeline.Item>
				  <Timeline.Item label="31.3.2022">
					  <ul style={{padding: 0, margin: "0.75rem"}}>
						  <li>
							  <a href="https://cvi2.uni.lu/sharp2022/challenge2/">SHARP</a>: Having decent results on the track 1.
						  </li>
						  <li>Thesis writing - all about the web app.</li>
					  </ul>
				  </Timeline.Item>
				  <Timeline.Item label="Goal">
					  <ul style={{padding: 0, margin: "0.75rem"}}>
						  <li>Finished web app.</li>
						  <li>Finished ML architecture, implementation and evaluation.</li>
						  <li>Written thesis.</li>
					  </ul>
				  </Timeline.Item>
			  </Timeline>
		  </div>
	  </section>
  )
}