import bibStyles from "../styles/bibliography.module.scss";
import {bibliography} from "data/bibliography";

const cite = require("citation-js");

const citations = new cite(bibliography);

const citationsOutput = citations.format("bibliography", {
	format: "html",
	template: "harvard",
	lang: "en-US",
	style: "bibtex"
});

export const Bibliography = () => {
	return (
		<section id="bibliography" className={`d-flex-col align-items-center`}>
			<header className="text-center">
				<h2>Bibliography</h2>
			</header>
			<div>
				<div>
					<a href="https://docs.google.com/presentation/d/1CkKjsi2Bnu4dmhJ4XTekSJq1MVp4HFDatlCmDTqkVG0/edit?usp=sharing">
						Presentation (30.11.2021)
					</a>
				</div>
				<div className={bibStyles.bibliography} dangerouslySetInnerHTML={{__html: citationsOutput}} />
			</div>
		</section>
	)
}