
export const ThesisChapters = () => {
  return (
	  <section id="thesis-structure" className={`d-flex-col align-items-center mb-6`}>
		  <header className="text-center">
			  <h2>Thesis chapters</h2>
		  </header>
		  <div>
			  <ul style={{padding: 0}}>
				  <li>Introduction</li>
				  <li>Terminology, definitions</li>
				  <li>Methods</li>
				  <li>Goals</li>
				  <li>Software Design</li>
				  <li>Implementation</li>
				  <li>Results and Conclusion</li>
			  </ul>
		  </div>
	  </section>
  )
}