import {ThesisChapters} from "./components/ThesisChapters";
import {Milestones} from "./components/Milestones";
import {Bibliography} from "./components/Bibliography";
import { Anchor } from 'antd';
import {useEffect, useState} from "react";
import {Introduction} from "./components/Introduction";
import {Annotation} from "./components/Annotation";
import {Aim} from "./components/Aim";
import {FirstChapters} from "./components/FirstChapters";
import {Diary} from "./components/Diary";
import {CurrentWork} from "./components/CurrentWork";

const { Link } = Anchor;

export const BachelorThesis = () => {

	const [targetOffset, setTargetOffset] = useState<number>();

	useEffect(() => {
		setTargetOffset((window.innerHeight / 2) * .75);
	}, []);

	return (<>
		<div className={`container-lg mb-6 container-center`}>
			<div className={`ml-6 mr-6`}>
				<Introduction />
				<div style={{position: "absolute", left: "25px"}}>
					<Anchor targetOffset={targetOffset} offsetTop={25}>
						<Link href="#introduction" title="Introduction" />
						<Link href="#annotation" title="Annotation" />
						<Link href="#aim" title="Aim" />
						<Link href="#diary" title="Diary" />
						<Link href="#current-work" title="Current work" />
						<Link href="#first-thesis-chapters" title="First chapters and Prototype" />
						<Link href="#thesis-structure" title="Thesis chapters" />
						<Link href="#milestones" title="Timeline & milestones" />
						<Link href="#bibliography" title="Bibliography" />
					</Anchor>
				</div>
				<Annotation />
				<Aim />
				<Diary />
				<CurrentWork />
				<FirstChapters />
				<ThesisChapters />
				<Milestones />
				<Bibliography />
			</div>
		</div>
	</>)
}