import {BackgroundParticles} from "components/particles/BackgroundParticles";

export const Introduction = () => {
  return (
    <header id="introduction" className={`d-flex-col w-100p justify-content-center text-center`}
            style={{height: "100vh"}}
    >
      <div>
        <h1 style={{fontSize: "4em", lineHeight: "1.2em"}}>
          Web Application
          for 3D data Conversion, Processing and Visualization
        </h1>
        <p>Bachelor's thesis, Martin Gergel</p>
        <p>Comenius University in Bratislava
          Faculty of Mathematics, Physics and Informatics
        </p>
        <p>
          Supervisor: Mgr. Lukáš Gajdošech
        </p>
        <BackgroundParticles />
      </div>
    </header>
  )
}