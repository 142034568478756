// TODO: create backend to this web app
// TODO: then bibliography could be fetched directly is a component

export const bibliography =
"@Book{deep_learning_with_python,\n" +
	"    author =  \t{François Chollet},\n" +
	"    title =     {Deep Learning with Python},\n" +
	"    edition =   {2},\n" +
	"    publisher = {Manning Publications},\n" +
	"    year =      {2021},\n" +
	"}\n" +
	"\n" +
	"@Book{nielsen_neural_networks,\n" +
	"  author = \t\t {Nielsen, Michael A.},\n" +
	"  publisher = \t{Determination Press},\n" +
	"  urldate = \t{2022-01-15},\n" +
	"  title = \t\t{Neural Networks and Deep Learning},\n" +
	"  url = \t\t{http://neuralnetworksanddeeplearning.com/},\n" +
	"  year = \t\t{2015}\n" +
	"}\n" +
	"\n" +
	"@Book{image_processing,\n" +
	"    author =    {Milan Sonka and Vaclav Hlavac and Roger Boyle},\n" +
	"    title =     {Image Processing, Analysis, and Machine Vision},\n" +
	"    edition =   {4},\n" +
	"    publisher = {Cengage Learning},\n" +
	"    year =      {2013},\n" +
	"}\n" +
	"\n" +
	"@Book{ai,\n" +
	"    author =    {Russell, Stuart J. and Peter Norvig and others},\n" +
	"    title =     {Artificial Intelligence: A Modern Approach},\n" +
	"    edition =   {3},\n" +
	"    publisher = {Prentice Hall},\n" +
	"    year =      {2010},\n" +
	"}\n" +
	"\n" +
	"@Book{ml,\n" +
	"    author =    {Mitchell, Tom M.},\n" +
	"    title =     {Machine Learning},\n" +
	"    publisher = {McGraw Hill},\n" +
	"    year =      {1997},\n" +
	"}\n" +
	"\n" +
	"@InProceedings{pcl,\n" +
	"  author    = {Radu Bogdan Rusu and Steve Cousins},\n" +
	"  title     = {{3D is here: Point Cloud Library (PCL)}},\n" +
	"  booktitle = {{IEEE International Conference on Robotics and Automation (ICRA)}},\n" +
	"  month     = {May 9-13},\n" +
	"  year      = {2011},\n" +
	"  address   = {Shanghai, China}\n" +
	"}\n" +
	"\n" +
	"@article{deep_learning_on_point_clouds,\n" +
	"  author    = {Yulan Guo and\n" +
	"               Hanyun Wang and\n" +
	"               Qingyong Hu and\n" +
	"               Hao Liu and\n" +
	"               Li Liu and\n" +
	"               Mohammed Bennamoun},\n" +
	"  title     = {Deep Learning for 3D Point Clouds: {A} Survey},\n" +
	"  journal   = {CoRR},\n" +
	"  volume    = {abs/1912.12033},\n" +
	"  year      = {2019},\n" +
	"  url       = {http://arxiv.org/abs/1912.12033},\n" +
	"  eprinttype = {arXiv},\n" +
	"  eprint    = {1912.12033},\n" +
	"  timestamp = {Sat, 23 Jan 2021 01:10:59 +0100},\n" +
	"  biburl    = {https://dblp.org/rec/journals/corr/abs-1912-12033.bib},\n" +
	"  bibsource = {dblp computer science bibliography, https://dblp.org}\n" +
	"}\n" +
	"\n" +
	"@article{modelnet,\n" +
	"  author    = {Zhirong Wu and\n" +
	"               Shuran Song and\n" +
	"               Aditya Khosla and\n" +
	"               Xiaoou Tang and\n" +
	"               Jianxiong Xiao},\n" +
	"  title     = {3D ShapeNets for 2.5D Object Recognition and Next-Best-View Prediction},\n" +
	"  journal   = {CoRR},\n" +
	"  volume    = {abs/1406.5670},\n" +
	"  year      = {2014},\n" +
	"  url       = {http://arxiv.org/abs/1406.5670},\n" +
	"  eprinttype = {arXiv},\n" +
	"  eprint    = {1406.5670},\n" +
	"  timestamp = {Mon, 13 Aug 2018 16:47:24 +0200},\n" +
	"  biburl    = {https://dblp.org/rec/journals/corr/WuSKTX14.bib},\n" +
	"  bibsource = {dblp computer science bibliography, https://dblp.org}\n" +
	"}\n" +
	"\n" +
	"@article{pointnet,\n" +
	"  author    = {Charles Ruizhongtai Qi and\n" +
	"               Hao Su and\n" +
	"               Kaichun Mo and\n" +
	"               Leonidas J. Guibas},\n" +
	"  title     = {PointNet: Deep Learning on Point Sets for 3D Classification and Segmentation},\n" +
	"  journal   = {CoRR},\n" +
	"  volume    = {abs/1612.00593},\n" +
	"  year      = {2016},\n" +
	"  url       = {http://arxiv.org/abs/1612.00593},\n" +
	"  eprinttype = {arXiv},\n" +
	"  eprint    = {1612.00593},\n" +
	"  timestamp = {Wed, 11 Nov 2020 08:48:10 +0100},\n" +
	"  biburl    = {https://dblp.org/rec/journals/corr/QiSMG16.bib},\n" +
	"  bibsource = {dblp computer science bibliography, https://dblp.org}\n" +
	"}\n" +
	"\n" +
	"@Online{pcl_basic_structures,\n" +
	"  title =     {Getting Started / Basic Structures},\n" +
	"  url =       {https://pointclouds.org/documentation/tutorials/basic_structures.html},\n" +
	"  urldate =   {2022-01-15},\n" +
	"}\n" +
	"\n" +
	"@Article{numpy,\n" +
	" title         = {Array programming with {NumPy}},\n" +
	" author        = {Charles R. Harris and K. Jarrod Millman and others},\n" +
	" year          = {2020},\n" +
	" month         = sep,\n" +
	" journal       = {Nature},\n" +
	" volume        = {585},\n" +
	" number        = {7825},\n" +
	" pages         = {357--362},\n" +
	" doi           = {10.1038/s41586-020-2649-2},\n" +
	" publisher     = {Springer Science and Business Media {LLC}},\n" +
	" url           = {https://doi.org/10.1038/s41586-020-2649-2}\n" +
	"}\n" +
	"% https://numpy.org/citing-numpy/ %\n" +
	"\n" +
	"@Online{brilliant_backpropagation,\n" +
	"\tauthor =\t{John McGonagle and George Shaikouski and Christopher Williams and others},\n" +
	"\ttitle = \t{Backpropagation},\n" +
	"\turl = \t\t{https://brilliant.org/wiki/backpropagation},\n" +
	"  \turldate = \t{2022-01-15},\n" +
	"}\n" +
	"\n" +
	"@Online{rest_api,\n" +
	"  author =  {IBM Cloud Education},\n" +
	"  title =   {REST APIs},\n" +
	"  url =     {https://www.ibm.com/cloud/learn/rest-apis},\n" +
	"  urldate =   {2022-01-15}\n" +
	"}\n" +
	"\n" +
	"@Online{react,\n" +
	"  author =  {Meta Platforms, Inc},\n" +
	"  title =   {React},\n" +
	"  url =     {https://reactjs.org},\n" +
	"  urldate =   {2022-01-15}\n" +
	"}\n" +
	"\n" +
	"@Online{redux,\n" +
	"  author =  {Dan Abramov and others},\n" +
	"  title =   {Redux},\n" +
	"  url =     {https://redux.js.org/},\n" +
	"  urldate =   {2022-01-15},\n" +
	"}\n" +
	"\n" +
	"@Online{bootstrap5,\n" +
	"  author =  {Mark Otto and Jacob Thornton and others},\n" +
	"  title =   {Bootstrap 5},\n" +
	"  url =     {https://getbootstrap.com/},\n" +
	"  urldate =   {2022-01-15},\n" +
	"}\n" +
	"\n" +
	"@Online{react_bootstrap,\n" +
	"  title =   {React Bootstrap},\n" +
	"  url =     {https://react-bootstrap.github.io/},\n" +
	"  urldate =   {2022-01-15},\n" +
	"}\n" +
	"\n" +
	"@Online{react_three_fiber,\n" +
	"  title =   {React Three Fiber},\n" +
	"  url =     {https://docs.pmnd.rs/react-three-fiber/getting-started/introduction},\n" +
	"  urldate =   {2022-01-15},\n" +
	"}\n" +
	"\n" +
	"@Online{react_query,\n" +
	"  author =  {Tanner Linsley and others},\n" +
	"  title =   {React Query},\n" +
	"  url =     {https://react-query.tanstack.com/},\n" +
	"  urldate =   {2022-01-15},\n" +
	"}\n" +
	"\n" +
	"@Online{axios,\n" +
	"  author =  {Tanner Linsley and others},\n" +
	"  title =   {Axios},\n" +
	"  url =     {https://axios-http.com/},\n" +
	"  urldate =   {2022-01-15},\n" +
	"}\n" +
	"\n" +
	"@article{open3d,\n" +
	"   author  = {Qian-Yi Zhou and Jaesik Park and Vladlen Koltun},\n" +
	"   title   = {{Open3D}: {A} Modern Library for {3D} Data Processing},\n" +
	"   journal = {arXiv:1801.09847},\n" +
	"   year    = {2018},\n" +
	"}\n" +
	"\n" +
	"@article{dgcnn,\n" +
	"  author    = {Yue Wang and\n" +
	"               Yongbin Sun and\n" +
	"               Ziwei Liu and\n" +
	"               Sanjay E. Sarma and\n" +
	"               Michael M. Bronstein and\n" +
	"               Justin M. Solomon},\n" +
	"  title     = {Dynamic Graph {CNN} for Learning on Point Clouds},\n" +
	"  journal   = {CoRR},\n" +
	"  volume    = {abs/1801.07829},\n" +
	"  year      = {2018},\n" +
	"  url       = {http://arxiv.org/abs/1801.07829},\n" +
	"  eprinttype = {arXiv},\n" +
	"  eprint    = {1801.07829},\n" +
	"  timestamp = {Thu, 16 Apr 2020 07:55:10 +0200},\n" +
	"  biburl    = {https://dblp.org/rec/journals/corr/abs-1801-07829.bib},\n" +
	"  bibsource = {dblp computer science bibliography, https://dblp.org}\n" +
	"}"