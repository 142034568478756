
export const CurrentWork = () => {
	return (
		<section id="current-work" className={`d-flex-col mb-6`}>
			<header className="text-center">
				<h2>Current work</h2>
			</header>
			<div className={`text-center`}>
				<a className="mr-3" href="https://drive.google.com/file/d/1M9IkIg51vxGmdnOktzV9e4abW8z6Axig/view?usp=sharing">Thesis</a>
				<a href="https://github.com/Ashen-MG/web-app-for-3d">Github</a>
			</div>
		</section>
	)
}