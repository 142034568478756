
export const Annotation = () => {
  return (
	  <section id="annotation" className={`d-flex-col mt-8 mb-6`}>
		  <header className="text-center">
			  <h2>Annotation</h2>
		  </header>
		  <div className={`text-justify`}>
			  Point clouds and 3D data in general come in various data formats. We need
			  a web application for conversion between these data formats and further
			  processing, without the need of specialized desktop applications. Processing
			  of complex point clouds and meshes is often limited to devices with powerful
			  hardware. Web applications on the other hand, can allow server-side processing.
			  Apart from conversion, a framework for processing tasks (e.g. noise filtering,
			  multi-view registration, upsampling, 3D surface reconstruction...) will be
			  created, allowing both classical approaches and novel machine learning based
			  alternatives. The user will have the choice between these different algorithms,
			  enabling direct comparison between traditional and novel data-driven solutions.
			  In some of the tasks, it is not directly clear which solutions are better than others
			  solely on existing evaluation metrics, as it is highly dependent on particular
			  use-case. Users will therefore be able to choose between alternative parts of the
			  processing algorithm. Finally, the application will allow visualization of results,
			  i.e. processed point clouds and meshes.
		  </div>
	  </section>
  )
}