
export const FirstChapters = () => {
	return (
		<section id="first-thesis-chapters" className={`d-flex-col mb-6`}>
			<header className="text-center">
				<h2>First chapters and Prototype (15.1.2022)</h2>
			</header>
			<div className={`text-center`}>
				<p>
					Thesis, program and screenshots (from 15.1.) can be found <a href="https://drive.google.com/drive/folders/1rE74CLd20xWw2jkIKA8ha2qG7T97jCDX?usp=sharing">
					here
				</a>
					. Active development runs on <a href="https://github.com/Ashen-MG/web-app-for-3d">github</a>.
				</p>
			</div>
		</section>
	)
}